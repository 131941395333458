import Image from 'components/BazarImage';
import FlexBox from 'components/FlexBox';
import NavLink from 'components/nav-link/NavLink';
import { Span } from 'components/Typography';
import { Container, useMediaQuery } from '@mui/material';
import { styled } from '@mui/material/styles';
import { layoutConstant } from 'utils/constants';
import Link from 'next/link';
import React from 'react';
import Instagram from '@mui/icons-material/Instagram';
import Facebook from '@mui/icons-material/Facebook';
import { useTheme } from '@emotion/react';



const TopbarWrapper = styled('div')(({
	theme, ismobile, istablet
}) => ({
	background: ismobile === "true" || istablet === "true" ? theme.palette.grey[100] : theme.palette.secondary.dark,
	color: ismobile === "true" ? theme.palette.secondary.contrastText : "white",
	height: layoutConstant.topbarHeight,
	fontSize: 12,
	'& .topbarLeft': {
		'& .logo': {
			display: 'none'
		},
		'& .title': {
			marginLeft: '10px'
		},
		'@media only screen and (max-width: 900px)': {
			'& .logo': {
				display: 'block'
			},
			'& > *:not(.logo)': {
				display: 'none'
			}
		}
	},
	'& .topbarRight': {
		'& .link': {
			paddingRight: 30,
			color: ismobile === "true" ? theme.palette.secondary.contrastText : "white"
		},
		'@media only screen and (max-width: 900px)': {
			'& .link': {
				display: 'none'
			}
		}
	},
	'& .smallRoundedImage': {
		height: 15,
		width: 25,
		borderRadius: 2
	},
	'& .handler': {
		height: layoutConstant.topbarHeight
	},
	'& .menuTitle': {
		fontSize: 12,
		marginLeft: '0.5rem',
		fontWeight: 600
	},
	'& .menuItem': {
		minWidth: 100
	},
	'& .marginRight': {
		marginRight: '1.25rem'
	}
}));

const Topbar = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
	const isTablet = useMediaQuery(theme.breakpoints.down('md'));


	return <TopbarWrapper ismobile={isMobile.toString()} istablet={isTablet.toString()}>
		<Container sx={{
			display: 'flex',
			justifyContent: 'space-between',
			alignItems: 'center',
			height: '100%'
		}}>
			<FlexBox className="topbarLeft" alignItems="center">
				<div className="logo">
					<Link href="/">
					{<Image height={28} mb={0.5} src="/assets/images/branding/logo/logo-header.png" alt="logo" />}

					</Link>
				</div>

				<FlexBox alignItems="center">
					<Instagram fontSize="small" />
					<Span className="title">@decheckers</Span>
				</FlexBox>
				<FlexBox alignItems="center" ml={2.5}>
					<Facebook fontSize="small" />
					<Span className="title">deCheckers</Span>
				</FlexBox>
			</FlexBox>

			<FlexBox className="topbarRight" alignItems="center">
				<NavLink className="link" href="/">
					Placeholder
				</NavLink>
				<NavLink className="link" href="/help">
					Placeholder
				</NavLink>
			</FlexBox>
		</Container>
	</TopbarWrapper>;
};

export default Topbar;