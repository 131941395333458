import { Paragraph } from 'components/Typography';
import { Box, Container, Grid, styled, Typography, useTheme, useMediaQuery, Card } from '@mui/material';
import React from 'react';
import { Facebook, Instagram, LinkedIn, QuestionMark, Twitter, } from '@mui/icons-material';
import Image from "next/image";
import { ArrowForward } from '@mui/icons-material'
import { useRouter } from 'next/router';
import BImage from 'components/BazarImage'
import Link from 'next/link';

const StyledLink = styled(Link)(({
	theme
}) => ({
	position: 'relative',
	display: 'block',
	padding: '0.3rem 0rem',
	color: 'black',
	cursor: 'pointer',
	textDecoration: 'underline',
	marginBottom: '5px',
	borderRadius: 4,
}));

const StyledColoredLink = styled('span')(({
	theme
}) => ({
	position: 'relative',
	display: 'block',
	padding: '0.3rem 0rem',
	color: 'black',
	cursor: 'pointer',
	color: '#fd5b72',
	fontWeight: 800,
	fontsize: 20,
	borderRadius: 4,
}));

const Footer = () => {
	const theme = useTheme();
	const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

	const router = useRouter();

	let bgColor = ['/contact', '/donatie', '/nieuwsbrief'].includes(router.asPath) ? 'white' : '#f0f2ff'

	return (
        <footer>
            <Box bgcolor={bgColor}>
                <Container sx={{
                    p: '1rem',
                    color: 'white'
                }}>
                    <BImage height={75} width={75} mt={-7} src="/assets/images/branding/icon-check.png" alt="logo" />
                    <Box paddingTop={2} overflow="hidden">
                        <Grid container spacing={3} mb={4}>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <Box fontSize="22px" fontWeight="900" mb={2.5} lineHeight="1" color="#171554">
                                    Snel naar
                                </Box>

                                <div>
                                    <StyledLink href="/over-ons">Over ons</StyledLink>
                                    <StyledLink href="/contact">Contact</StyledLink>
                                    <StyledLink href="/factcheck-suggereren">Factcheck suggereren</StyledLink>
                                    <StyledLink href="/cookies">Ons cookiebeleid</StyledLink>
                                </div>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <div>
                                    <Link href="/factcheck-suggereren">
                                        <StyledColoredLink style={{ display: 'flex', alignItems: 'center' }}>
                                            <ArrowForward sx={{ color: '#fd5b72' }} /> Tip ons! Suggereer zelf een factcheck
                                        </StyledColoredLink>
                                    </Link>

                                    <Link href="/donatie">
                                        <StyledColoredLink style={{ display: 'flex', alignItems: 'center' }}>
                                            <ArrowForward sx={{ color: '#fd5b72' }} /> Doneer
                                        </StyledColoredLink>
                                    </Link>

                                    <Card sx={{mt: 2}}>
                                        <Link href="/nieuwsbrief" style={{ cursor: 'pointer' }}>
                                            <Box padding={2} sx={{ backgroundColor: '#e5e9f9', pb: 1, cursor: 'pointer' }}>
                                                <Typography variant="p" fontWeight="bold" mb={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                                    Blijf op de hoogte via onze nieuwsbrief
                                                </Typography>
                                                <Typography variant="p" mb={1} sx={{ display: 'flex', alignItems: 'center' }}>
                                                    De nieuwste factchecks en tips elke week in je mailbox.
                                                </Typography>
                                                <div style={{ display: 'flex', alignItems: 'left', cursor: 'pointer' }}>
                                                    <div style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}>
                                                        <ArrowForward sx={{ color: '#fd5b72', marginRight: '0.5rem' }} />
                                                        <div style={{ color: '#fd5b72', fontWeight: 500 }}>
                                                            Gratis inschrijven
                                                        </div>
                                                    </div>
                                                </div>
                                            </Box>
                                        </Link>
                                    </Card>

                                </div>
                            </Grid>
                            <Grid item lg={4} md={6} sm={6} xs={12}>
                                <Box fontSize="22px" fontWeight="900" mb={2.5} lineHeight="1" color="#171554">
                                    Volg ons
                                </Box>
                                <div>
                                    <div style={{ display: "flex", alignItems: "center", }}>
                                        <div style={{ marginRight: "0.5em" }}>
                                            <Twitter style={{ color: "black", fontSize: "1.2em", color: "#171554" }} />
                                        </div>
                                        <div>
                                            <StyledLink style={{ fontSize: "1.2em", color: "#3A3A3A", fontFamily: "Poppins" }} target={"_blank"} href="https://twitter.com/deCheckers_be">
                                                Twitter
                                            </StyledLink>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", }}>
                                        <div style={{ marginRight: "0.5em" }}>
                                            <Facebook style={{ color: "black", fontSize: "1.2em", color: "#171554" }} />
                                        </div>
                                        <div>
                                            <StyledLink style={{ fontSize: "1.2em", color: "#3A3A3A", fontFamily: "Poppins" }} target={"_blank"} href="https://www.facebook.com/deCheckers/"> Facebook</StyledLink>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", }}>
                                        <div style={{ marginRight: "0.5em" }}>
                                            <Instagram style={{ color: "black", fontSize: "1.2em", color: "#171554" }} />
                                        </div>
                                        <div>
                                            <StyledLink style={{ fontSize: "1.2em", color: "#3A3A3A", fontFamily: "Poppins" }} target={"_blank"} href="https://www.instagram.com/decheckers_be/">Instagram</StyledLink>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", }}>
                                        <div style={{ marginRight: "0.5em" }}>
                                            <LinkedIn style={{ color: "black", fontSize: "1.2em", color: "#171554" }} />
                                        </div>
                                        <div>
                                            <StyledLink style={{ fontSize: "1.2em", color: "#3A3A3A", fontFamily: "Poppins" }} target={"_blank"} href="https://www.linkedin.com/company/decheckers/">LinkedIn</StyledLink>
                                        </div>
                                    </div>
                                    <div style={{ display: "flex", alignItems: "center", }}>
                                        <div style={{ marginRight: "0.5em" }}>
                                            <img width={16} src={'/assets/images/tiktok.svg'} style={{ color: "black", fontSize: "1.2em", color: "#171554" }} />
                                        </div>
                                        <div>
                                            <StyledLink style={{ fontSize: "1.2em", color: "#3A3A3A", fontFamily: "Poppins" }} target={"_blank"} href="https://www.tiktok.com/@decheckers_be">TikTok</StyledLink>
                                        </div>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                        <Image
                            height={46}
                            width={178}
                            src="/assets/images/branding/logo/logo-footer.png"
                            alt="logo"
                            style={{
                                marginTop: 10,
                                maxWidth: "100%",
                                height: "auto"
                            }} />
                    </Box>

                </Container>
                <Grid>
                    <Grid item xs={12} style={{ background: '#171554' }} height={75}>
                        <Paragraph color="grey.500" style={{ fontSize: isMobile ? 10 : null }} px={5} pt={3} pl={11}>
                            &copy; Copyright {new Date().getFullYear()} &nbsp;
                            deCheckers VZW &emsp;
                            <Link href='/cookies' style={{ textDecoration: 'underline' }}>Cookiebeleid</Link> &emsp;
                            <Link href='/privacy' style={{ textDecoration: 'underline' }}>Privacy beleid</Link> &emsp;
                        </Paragraph>
                    </Grid>
                </Grid>
            </Box>
        </footer>
    );
};

export default Footer;